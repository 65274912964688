<template>
  <div id="newsEdit">
    <app-menu></app-menu>
    <div class="col-lg-10 offset-lg-1">
      <h3 class="text-center pb-lg-4">Aktualita</h3>
      <div class="row pt-lg-5">
        <div class="col-lg-6">
          <h4 class="text-center">Čestina</h4>
          <div class="form-group">
            <label>Nadpis:</label>
            <input type="text" class="form-control" v-model="article.title.cs" placeholder="Název aktuality">
          </div>
          <div class="form-group">
            <label>Náhled:</label>
            <textarea class="form-control" v-model="article.preview.cs" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label>Text:</label>
            <html-editor v-model="article.text.cs"></html-editor>
            <textarea v-if="isDevActive" class="form-control" v-model="article.text.cs" rows="3"></textarea>
          </div>
        </div>
        <div class="col-lg-6">
          <h4 class="text-center">Angličtina</h4>
          <div class="form-group">
            <label>Nadpis:</label>
            <input type="text" class="form-control" v-model="article.title.en" placeholder="Název aktuality">
          </div>
          <div class="form-group">
            <label>Náhled:</label>
            <textarea class="form-control" v-model="article.preview.en" rows="3"></textarea>
          </div>
          <div class="form-group">
            <label>Text:</label>
            <html-editor v-model="article.text.en"></html-editor>
          </div>
          <textarea v-if="isDevActive" class="form-control" v-model="article.text.en" rows="3"></textarea>
        </div>
      </div>
      <div class="row pb-lg-4">
        <div class="col-lg-6">
          <div>
            <label>Datum vytvoření:</label>
            <input type="date" v-model="article.created" class="form-control">
          </div>
        </div>
        <div class="col-lg-6">
        </div>
      </div>
      <div class="row pt-lg-4">
        <div class="col-lg-6">
          <h5 class="text-center">Foto - náhled:</h5>
          <div class="form-group pt-lg-4">
            <input type="file" class="form-control-file pt-lg-4" accept="image/jpeg" @change="selectMainImage"
                   aria-describedby="fileHelp">
            <small class="form-text text-muted"></small>
          </div>
          <a v-if="mainImage.length > 0" class="closeButton" type="button" v-on:click="deleteMainPhoto()"
             :id="mainImage">✕</a>
          <img v-if="mainImage.length > 0" class="img-fluid" width="50%" :src="mainImage">
        </div>
        <div class="col-lg-6">
          <h5 class="text-center">Foto - ostatní:</h5>
          <div class="form-group pt-lg-4 tex">
            <input type="file" class="form-control-file pt-lg-4" accept="image/jpeg" @change="selectImage"
                   aria-describedby="fileHelp">
            <small class="form-text text-muted"></small>
          </div>
          <div v-for="(image, index) in images" v-bind:key="index">
            <a class="closeButton" type="button" v-on:click="deletePhoto(image)" :id="index">✕</a>
            <img class="img-fluid pb-lg-2" width="50%" :src="image">
          </div>
        </div>
      </div>
      <div class="form-check form-switch pt-lg-4 text-center">
        <input id="visible" v-model="article.visible" class="form-check-input" type="checkbox">
        <label class="form-check-label" for="visible">Viditelný</label>
      </div>
      <div class="text-center pt-lg-4">
        <send-button
            :send="create"
            text="Uložit Aktualitu"
            :response="error"
        />
      </div>
    </div>
    <error-modal :message="error"/>
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import axios from "@/api/axiom-instance";
import config from "@/config"
import ErrorModal from "@/components/admin/ErrorModal";
import SendButton from "@/components/admin/SendButton";
import {VueEditor} from "vue2-editor";

export default {
  name: "newsEdit",
  components: {
    'app-menu': AdminMenu,
    'error-modal': ErrorModal,
    'send-button': SendButton,
    'html-editor': VueEditor
  },
  computed: {
    isDevActive: function () {
      return localStorage.getItem('dev') === 'true'
    },
  },
  data() {
    return {
      article: {title: {}, text: {}, preview: {}},
      mainImage: "",
      images: [],
      error: null
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    create: function () {
      axios.post("/article", this.article).then((success) => {
        axios.post("/image", {name: success.data.id, type: "article-main", data: this.mainImage}).then(() => {
          axios.post("/image/bulk", {name: success.data.id, type: "article-others", data: this.images}).then(() => {
            this.$router.push({path: "/admin/news"})
          }).catch(error => this.error = error)
        }).catch(error => this.error = error)

      }).catch(error => this.error = error)
    },
    selectMainImage: function (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = e => {
        this.mainImage = e.target.result
      }
    },
    selectImage: function (e) {
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = e => {
        this.images.push(e.target.result)
      }
    },
    load: function (id) {
      axios
          .get("/article", {params: {id: id}})
          .then((success) => {
            this.article = success.data;
          }).catch(error => this.error = error)
      this.mainImage = config.API_URL + "/image?type=article-main&name=" + id
      axios
          .get("/image/list", {params: {type: "article-others/" + id}})
          .then((success) => {
            this.images = success.data.map(it => it.src)
          }).catch(error => this.error = error)
    },
    deleteMainPhoto: function () {
      this.mainImage = "";
    },
    deletePhoto: function (value) {
      this.images = this.images.filter(it => it !== value);
    }
  }
}
</script>

<style scoped>
.noneVisible {
  display: none;
}

#newsEdit {
  min-height: 100vh;
  padding-bottom: 4em;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  background-color: black;
  color: white;
  padding: 3px 7px;
}
</style>
